import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {
        derived_properties: {frame_type: {
          in: ["damcykel", "herrcykel", "cykel"]
        }}
      }) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "klassiska-elcyklar"
    }}>{`Klassiska elcyklar`}</h1>
    <p>{`En klassisk cykel liknar "gammla" klassiska herr- eller damcyklar. Vanligtvis har man en ganska upprätt sittposition och cykelkorg där fram och pakethållare där bak är mer regel än undantag.`}</p>
    <h2 {...{
      "id": "sittposition"
    }}>{`Sittposition`}</h2>
    <p>{`På en klassisk cykel sitter man väldigt upprätt vilket gör att de är väldigt bekväma att cykla. Detta möjligör också ofta lite större och mer bekväma sadlar vilket kan vara till en fördel för komforten. En nackdel med en upprätt cykelposition är att man inte kan kräma ur lika mycket kraft från benen till tramporna, men när det kommer till elcyklar är inte detta hela värden då du har en elmotor som hjälper till att trycka på. Skulle däremot batteriet ta slut så kan dessa cyklar var väldigt jobba att cykla på.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      